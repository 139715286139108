import { toCurrency } from "@wisr/common";
import { Button } from "@wisr/react-ui";
import { Link } from "gatsby";
import React from "react";
import {
  analyticsClickEvent,
  analyticsLoadLoanWidgetEvent,
} from "../../../../shared/analytics/analytics.utils";
import {
  HighlightComponent,
  HighlightItem,
} from "../../../shared/highlight/highlight.component";
import { WidgetComponent } from "../../../widget.component";
import { extractLoanEventData } from "../../personal-loan/personal-loan-borrowing-widget.utils";
import localStyle from "./loan-overview-borrowing-widget.scss";
import { PersonalLoan } from "../../../../types/personal-loan";

export interface SingleOverviewBorrowingWidget {
  loan: PersonalLoan;
  loanInfo: HighlightItem[];
}

export const LoanOverviewBorrowingWidgetComponent: React.FC<
  SingleOverviewBorrowingWidget
> = ({ loan, loanInfo }) => {
  React.useEffect(() => {
    analyticsLoadLoanWidgetEvent("Loan", extractLoanEventData(loan));
  }, [loan]);

  return (
    <WidgetComponent widgetName="overview" widgetType="borrow">
      <style jsx>{localStyle}</style>

      <div className="widget-wrapper">
        <div className="widget-header">
          <div className="widget-title">
            <h2>LOAN ID {loan.loanId}</h2>
          </div>
          <h3 className="widget-subtitle">
            {toCurrency(loan.originalLoanAmount || 0)} &#8226; {loan.loanType}{" "}
            Personal Loan
          </h3>
        </div>
        <div className="widget-data">
          <HighlightComponent variation="compact" list={loanInfo} />
        </div>
        <div className="cta">
          <Button button="secondary">
            <Link
              to={`/borrowing/loans?loanId=${loan.loanId}`}
              onClick={() => {
                analyticsClickEvent("View loan details");
              }}
            >
              View Loan Details
            </Link>
          </Button>
        </div>
      </div>
    </WidgetComponent>
  );
};
