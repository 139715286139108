import { PageProps } from "gatsby";
import React from "react";
import { analyticsViewPageEvent } from "../../shared/analytics/analytics.utils";
import { isBrowser } from "@wisr/common";
import { OverviewBorrowingWidget } from "../../widgets/borrowing/overview/overview-borrowing-widget.component";
import indexGlobalStyle from "./index.global.scss";

const BorrowingPage: React.FC<PageProps> = () => {
  if (isBrowser()) {
    analyticsViewPageEvent("Borrowing");
  } else {
    return null;
  }
  return (
    <div className="content">
      <style jsx>{indexGlobalStyle}</style>
      <h1>Loans</h1>
      <OverviewBorrowingWidget />
    </div>
  );
};

export default BorrowingPage;
