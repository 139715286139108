import { toCurrency } from "@wisr/common";
import { Button, ButtonRow } from "@wisr/react-ui";
import React from "react";
import { useDispatch } from "react-redux";
import {
  analyticsClickEvent,
  analyticsCustomEvent,
} from "../../../../shared/analytics/analytics.utils";
import { BadgeComponent } from "../../../../shared/badge/badge.component";
import { HighlightComponent } from "../../../shared/highlight/highlight.component";
import { WidgetComponent } from "../../../widget.component";
import { ApplicationStatus } from "../../personal-loan/personal-loan-borrowing.constants";
import loanStyle from "../loan/loan-overview-borrowing-widget.scss";
import appStyle from "./application-overview-borrowing-widget.scss";
import { fetchLoanOffer } from "../../../../redux/loan-offer/loan-offer.thunk";
import { useGetLoanOfferState } from "../../../../redux/loan-offer/loan-offer.hooks";
import { navigate } from "gatsby";
import { applicationClearAction } from "../../../../application/application-form.actions";
import { IncompleteLoanApplication } from "../../../../types/personal-loan";
import { APPLICATION_DAY_UPDATED_LIMIT } from "./application-overview.consts";

export interface SingleOverviewBorrowingWidget {
  application: IncompleteLoanApplication;
  status: ApplicationStatus;
  active: boolean;
}

export const ApplicationOverviewBorrowingWidgetComponent: React.FC<
  SingleOverviewBorrowingWidget
> = ({ application, status, active }) => {
  const dispatch = useDispatch();
  const loanOffers = useGetLoanOfferState().loanOffers;
  const daysLeft = application.expiryInDays ?? 0;
  const daysLeftDisplay =
    daysLeft !== 1 ? `${daysLeft} days` : `${daysLeft} day`;

  const clickEvent = () => {
    if (
      sessionStorage.getItem("ContinueToken") &&
      loanOffers.length > 1 &&
      application.quoteId
    ) {
      sessionStorage.removeItem("ContinueToken");
      dispatch(applicationClearAction());
      dispatch(fetchLoanOffer(application.quoteId));
    }
    analyticsClickEvent("Continue application");
    analyticsCustomEvent("clickedIncompleteLoanApplicationWidget", {
      "Loan id": application.loanId,
    });
    navigate(`/application?loanid=${application.loanId}`);
  };

  React.useEffect(() => {
    analyticsCustomEvent("loadedIncompleteLoanApplicationWidget", {
      "Loan id": application.loanId,
    });
  }, [application.loanId]);

  return (
    <WidgetComponent widgetName="overview" widgetType="borrow">
      <style jsx>{loanStyle}</style>
      <style jsx>{appStyle}</style>

      <div className="widget-wrapper">
        <div className="widget-header">
          <div className="widget-title">
            <h2>LOAN ID {application.loanId}</h2>
            {status && (
              <BadgeComponent
                badge={status === "incomplete" ? "warning" : "info"}
              >
                {status}
              </BadgeComponent>
            )}
          </div>
          <h3 className="widget-subtitle">
            {application.amount?.toString()
              ? toCurrency(application.amount)
              : ""}{" "}
            <span className="divider">&#8226;</span> Personal Loan
          </h3>
        </div>
        <div className="widget-data">
          <HighlightComponent
            variation="compact"
            list={[
              {
                type: "main",
                title: "1 of 3 steps complete",
                description: "Application form progress",
              },
              {
                type: "small",
                title: daysLeftDisplay,
                description: "Days until expiry",
                tooltip: `Your application is valid for ${APPLICATION_DAY_UPDATED_LIMIT} days from the date of rate estimate`,
              },
            ]}
          />
        </div>
        <div className="cta">
          <ButtonRow>
            <Button button={active ? "primary" : "secondary"}>
              <button onClick={clickEvent}>Continue application</button>
            </Button>
          </ButtonRow>
        </div>
      </div>
    </WidgetComponent>
  );
};
