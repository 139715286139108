import { isDefined, toCurrency, useLoadingStatus } from "@wisr/common";
import { ContentScroller, SkeletonLoader } from "@wisr/react-ui";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { ArrearsLoan } from "../../../loans/arrears/arrears-loan";
import { DischargedLoan } from "../../../loans/discharged/discharged-loan";
import { stringToDate } from "../../../shared/date.utils";
import { ErrorWidgetComponent } from "../../shared/error/error-widget.component";
import { NavLink } from "../../shared/nav-link/nav-link.component";
import { WidgetComponent } from "../../widget.component";
import { loanBorrowingGetAction } from "../personal-loan/personal-loan-borrowing-widget.actions";
import { GETLOAN_BORROWING_LOADING } from "../personal-loan/personal-loan-borrowing-widget.epic";
import {
  useGetIncompleteApplications,
  useGetLoans,
} from "../personal-loan/personal-loan-borrowing-widget.hooks";
import { shouldTreatAsInArrears } from "../personal-loan/personal-loan-borrowing-widget.utils";
import { LoanStatuses } from "../personal-loan/personal-loan-borrowing.constants";
import { ApplicationOverviewBorrowingWidgetComponent } from "./application/application-overview-borrowing-widget.component";
import { LoanOverviewBorrowingWidgetComponent } from "./loan/loan-overview-borrowing-widget.component";
import overviewStyle from "./overview-borrowing-widget.scss";
import { PersonalLoan } from "../../../types/personal-loan";

export const OverviewBorrowingWidget: React.FC<{
  variation?: "home";
}> = ({ variation = "" }) => {
  const [loading] = useLoadingStatus(GETLOAN_BORROWING_LOADING);
  const [loanInfo, setLoanInfo] = useState<JSX.Element[]>();
  const [applicationInfo, setApplicationInfo] = useState<JSX.Element[]>();

  const dispatch = useDispatch();
  const loans = useGetLoans();
  const incompleteApplications = useGetIncompleteApplications();

  React.useEffect(() => {
    if (!isDefined(loans)) {
      dispatch(loanBorrowingGetAction());
    }
  }, [loans, dispatch]);

  React.useEffect(() => {
    if (loans && loans.length > 0) {
      const loanInfoArray = loans.map((loan) => mapLoanType(loan));
      setLoanInfo(loanInfoArray);
    }
  }, [loans]);

  React.useEffect(() => {
    if (incompleteApplications.length > 0) {
      const customerApps = incompleteApplications.map((application, index) => {
        return (
          <ApplicationOverviewBorrowingWidgetComponent
            application={application}
            key={application.loanId}
            status={"incomplete"}
            active={index === 0}
          />
        );
      });
      setApplicationInfo(customerApps);
    }
  }, [incompleteApplications]);

  if (loading) {
    return (
      <div className="loading-wrapper">
        <WidgetComponent widgetName="borrow-overview" widgetType="borrow">
          <SkeletonLoader lines={7} />
        </WidgetComponent>
      </div>
    );
  }
  if (isDefined(loans) || (applicationInfo?.length || 0) > 0) {
    return (
      <>
        <style jsx>{overviewStyle}</style>
        {variation === "home" ? (
          <>
            {applicationInfo && applicationInfo.length > 0 && (
              <>{applicationInfo}</>
            )}
            {loanInfo && loanInfo.length > 0 && <>{loanInfo}</>}
          </>
        ) : (
          <div className="overview-loans-wrapper">
            {applicationInfo && applicationInfo.length > 0 && (
              <div className="loans">
                <div className="loan-title">
                  <h2>Applications</h2>
                  <NavLink to="./applications/" size="small">
                    View all applications
                  </NavLink>
                </div>
                <ContentScroller mobileOnly>{applicationInfo}</ContentScroller>
              </div>
            )}
            {loanInfo && loanInfo.length > 0 && (
              <div className="loans">
                <ContentScroller mobileOnly>{loanInfo}</ContentScroller>
              </div>
            )}
          </div>
        )}
      </>
    );
  }

  return (
    <WidgetComponent widgetName="borrow-overview-error" widgetType="borrow">
      <ErrorWidgetComponent />
    </WidgetComponent>
  );
};

const mapLoanType = (loan: PersonalLoan) => {
  switch (loan.status) {
    case LoanStatuses.DISCHARGED:
      return <DischargedLoan variation="home" loan={loan} key={loan.loanId} />;
    case LoanStatuses.PART_9_AGREEMENT:
    case LoanStatuses.WRITTEN_OFF:
    case LoanStatuses.ARREARS:
      if (shouldTreatAsInArrears(loan)) {
        return <ArrearsLoan loan={loan} key={loan.loanId} />;
      }
    // fallthrough
    default:
      return (
        <LoanOverviewBorrowingWidgetComponent
          loan={loan}
          loanInfo={[
            {
              description: "Loan balance",
              title: toCurrency(loan.currentBalance || 0, true),
              type: "full",
            },
            {
              description: "Next debit amount",
              title: toCurrency(loan.repaymentAmount || 0, true),
              type: "small",
            },
            {
              description: "Next debit date",
              title: stringToDate(loan.nextRepaymentDate || "", "full"),
              type: "small",
            },
          ]}
          key={loan.loanId}
        />
      );
  }
};
