import { toCurrency } from "@wisr/common";
import { Button, StatusCallout } from "@wisr/react-ui";
import { Link } from "gatsby";
import React from "react";
import {
  analyticsClickEvent,
  analyticsCustomEvent,
  analyticsLoadLoanWidgetEvent,
} from "../../shared/analytics/analytics.utils";
import {
  extractLoanEventData,
  trimLoanStatus,
} from "../../widgets/borrowing/personal-loan/personal-loan-borrowing-widget.utils";
import loanStyle from "../../widgets/borrowing/personal-loan/personal-loan.scss";
import { WidgetComponent } from "../../widgets/widget.component";
import style from "./arrears-loan.scss";
import { PersonalLoan } from "../../types/personal-loan";

interface ArrearsLoanProp {
  loan: PersonalLoan;
}

export const ArrearsLoan: React.FC<ArrearsLoanProp> = ({ loan }) => {
  const arrearsVal = loan.totalArrearsAmount
    ? toCurrency(loan.totalArrearsAmount)
    : "";

  React.useEffect(() => {
    analyticsCustomEvent("viewedArrearsCallout", {
      "Page name": "Dashboard home",
      "Loan status": trimLoanStatus(loan.status),
    });
    analyticsLoadLoanWidgetEvent("Loan", extractLoanEventData(loan));
  }, [loan]);

  return (
    <WidgetComponent widgetName="personal-loan" widgetType="borrow">
      <style jsx>{loanStyle}</style>
      <style jsx>{style}</style>
      <div className={`widget-title`}>
        <h2>Loan ID {loan.loanId}</h2>
      </div>
      <div className="widget-subtitle">
        <p>
          {toCurrency(loan.originalLoanAmount || 0)} &#8226; {loan.loanType}{" "}
          Personal Loan
        </p>
      </div>
      <section className="widget-data">
        <div className="content">
          <StatusCallout
            type="error"
            heading={`Your account is ${arrearsVal} overdue`}
            theme="outline"
            message="You've missed one or more loan repayments."
          />
        </div>
        <Button button="secondary" contrast>
          <Link
            to={`/borrowing/loans?loanId=${loan.loanId}`}
            onClick={() => analyticsClickEvent("View loan details")}
          >
            View loan details
          </Link>
        </Button>
      </section>
    </WidgetComponent>
  );
};
